import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ListSubheader, Box, List, Button, ListItem } from '@mui/material';
import { NavLink as RouterLink } from 'react-router-dom';
import { SidebarContext } from 'src/providers/SidebarContext';
import * as ROUTES from 'src/shared/constants/routes';
import DnsIcon from '@mui/icons-material/Dns';
import { useDispatch } from 'react-redux';
import { setSelectedUserData } from 'src/modules/user-management/services/UserManagement.service';
interface sidebarmenuprops {
  menuname: string;
  option: sidebarprop[];
}

export interface sidebarprop {
  pathname: string;
  subpathname: string;
  icon: JSX.Element;
  submenuname: string;
}

export const SidebarMenus = ({ menuname, option }: sidebarmenuprops) => {
  const { closeSidebar, updateHeaderTitle } = useContext(SidebarContext);
  const { t } = useTranslation('sidebarmenu');
  const dispatch = useDispatch();

  //TODO: Needs to be updated when there are multiple menu items.
  useEffect(() => {
    if (option.length > 0) {
      updateHeaderTitle(option[0]?.submenuname);
    }
  }, []);

  const handleMenuChange = (value) => {
    updateHeaderTitle(value);
  };

  const SidebarItem = (option) => {
    return (
      <Box className="SubMenuWrapper">
        <List component="div">
          {option.map((item, index) => {
            return (
              <ListItem component="div" key={index}>
                <Button
                  disableRipple
                  component={RouterLink}
                  onClick={() => {
                    closeSidebar();
                    handleMenuChange(item.submenuname);
                    if (item.pathname === ROUTES.USER_MANAGEMENT) {
                      dispatch(setSelectedUserData(null));
                    }
                  }}
                  to={`${item.pathname}${item.subpathname}`}
                  startIcon={item.icon}
                >
                  {/* {t(item.submenuname)} */}
                </Button>
              </ListItem>
            );
          })}
        </List>
      </Box>
    );
  };
  return (
    <>
      <Box className="MenuWrapper ">
        {menuname && option.length ? (
          <List
            component="div"
            subheader={
              <ListSubheader component="div" disableSticky>
                {t(menuname)}
              </ListSubheader>
            }
          >
            {SidebarItem(option)}
          </List>
        ) : (
          <> {SidebarItem(option)}</>
        )}
        {/* </List> */}
      </Box>
    </>
  );
};
